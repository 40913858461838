import React, { Component } from 'react';
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { auth, db, firebase } from '../firebase';
import styled from 'styled-components';
import colors from '../styles/colors';
import { Layout, Header, Heading, Content, MainAction } from '../Components/AppChrome';
import { SearchBox, SearchHits, SearchHit } from '../Components/Search';
import SortableList from '../Components/SortableList';
import { SecondaryButton } from '../Components/Button';

//TODO:
// * Add preferredLanguage and email to user object
// * Client-side validation of numbers, email address, string lengths
// * Hover and click effects
// * Move up/down animation (transform: translateY(100%/-100%))
// * Verify email adress of new user? Don't allow invalid characters! No double @ ... @
// * Localized strings, timestamps
// * Error message when user already exists
// * Possibility to "claim" customers without a clinic
// * Add photoUrl to user if missing
// * Autosvar för anmälan till föreläsning
// * Scroll up automatically on program list: show + new
// * User should not be able to delete herself (permissions also!)
// * Edit email and google account for user
// * ADD DELETE BUTTON AND DELETEABLE PROPERTY

const Title = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  padding: 1rem 1rem 0rem 1rem;
`;

const TextAction = styled.span`
  font-size: 0.75rem;
  text-decoration: underline;
  color: ${colors.first};
`;

const List = styled.ul`
  line-height: 1.4;
  margin: 0;
  padding: 0.375rem 1rem 5rem 2rem;
`;

const Image = styled(Img)`
  width: 100%;
  height: 100vw;
  max-height: 843px;
`;

const ImageSpacer = styled.div`
  padding-top: 6rem;
`;

// const Info = styled.div`
//   padding: 1rem;
//   padding-top: 7rem;
// `;

const InfoBottom = styled.div`
  width: 100%;
  max-width: 843px;
  position: absolute;
  bottom: 0;
  background-color: white;
  opacity: 0.9;
  cursor: pointer;
`;

// const InputContainer = styled.div`
//   padding: 0rem 1rem 1rem;
//   background-color: ${colors.first};
// `;

// const Input = styled.input`
//   width: 100%;
//   height: 1rem;
//   background-color: ${colors.bright};
//   padding: 0.5rem 1rem 0.5rem 1rem;
//   font-size: 1rem;
//   border-radius: 2px;
//   border: 1px solid ${colors.first};
//   outline: none;
//   color: ${colors.dark};

//   &::placeholder {
//     color: ${colors.light};
//   }
// `;

// const TextArea = styled.textarea`
//   width: 100%;
//   height: 10rem;
//   background-color: white;
//   padding: 1rem;
//   font-size: 1rem;
//   border-radius: 2px;
//   border: 2px solid ${colors.first};
//   outline: none;
//   color: ${colors.dark};
//   font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

//   &::placeholder {
//     color: ${colors.light};
//   }
// `;

// const Flex = styled.div`
//   display: flex;
// `;

// const InputText = (props) => {
//   return (
//     <Flex>
//       <Input 
//         type={"text"}
//         value={props.value}
//         onChange={props.onChange}
//         placeholder={props.placeholder}
//       />
//     </Flex>
//   );
// };

// const InputTextArea = (props) => {
//   return (
//     <Flex>
//       <TextArea 
//         value={props.value}
//         onChange={props.onChange}
//         placeholder={props.placeholder}
//       />
//     </Flex>
//   );
// };

class NewPage extends Component {
  constructor(props) {
    super(props);
    this.searchBox = null;

    this.state = {
      authenticating: false,
      authUser: null,
      // programName: '',
      currentProgram: 0,
      currentExerciseNumber: null,
      nextExerciseId: 1,
      exercises: [],
      allInstructions: [],
      instructions: [],
      searchText: '',
      practitioner: null,
      user: null,
      userId: '',
      showingInstruction: null,
      showingInstructionStep: 0,
      showingInstructionDetails: false,
      addingExercise: false,
      // addingMessage: false,
      // message: 'Lycka till med träningen!\n\n'
    };

    this.onChangeGrouped = this.onChangeGrouped.bind(this);
    this.onChangeShortTrack = this.onChangeShortTrack.bind(this);
    this.onChangeMultiTrack = this.onChangeMultiTrack.bind(this);
    this.onRemoveExercise = this.onRemoveExercise.bind(this);
    this.setSearchBoxRef = this.setSearchBoxRef.bind(this);
  }

  setSearchBoxRef(searchBoxRef) {
    this.searchBox = searchBoxRef;
  }

  onFocusSearchBox() {
    if (this.searchBox) {
      this.searchBox.focus();
    }
  }
    
  onSignIn = () => {
    auth.doGoogleSignInWithRedirect();
  }
  
  // onAddMessage = () => {
  //   this.setState({ addingMessage: true }, () => {
  //     window.scrollTo(0, 0);
  //   });
  // }
  
  // onCancelAddMessage = () => {
  //   this.setState({ addingMessage: false });
  // }
  
  // onPublishProgram = () => {
  //   db.publishProgram(this.state.user.id, this.state.currentProgram, this.state.programName, this.state.message, false);
  //   this.setState({
  //       addingMessage: false
  //   });
  //   navigate('/?user=' + encodeURIComponent(this.state.user.id));
  // }

  // onPublishProgramPractitioner = () => {
  //   db.publishProgram(this.state.user.id, this.state.currentProgram, this.state.programName, this.state.message, true);
  //   this.setState({
  //       addingMessage: false
  //   });
  //   navigate('/?user=' + encodeURIComponent(this.state.user.id));
  // }

  onSelect = event => {
    const selectedIndex = Number(event.currentTarget.id);
    if (this.state.currentExerciseNumber === selectedIndex) {
      this.setState({ currentExerciseNumber: null});
    } else {
      this.setState({ currentExerciseNumber: Number(event.currentTarget.id)});
    }
  }

  onKeypress = event => {
    switch (event.keyCode) {
      case 13:  //enter
        event.currentTarget.id = this.state.instructions.length > 0 ? this.state.instructions[0].id : null;
        this.addExercise(event);
        break;
      case 27:  //escape
        this.onCancelAddExercise(event);
        break;
      case 32:  //space
        this.setState({ currentExerciseNumber: null });
        break;
      case 37:  //left
      case 38:  //up
        if (this.state.exercises && this.state.exercises.length > 0) {
          event.currentTarget.parentElement.parentElement.id = this.state.currentExerciseNumber;
          this.onMoveUp(event);
        }
        break;
      case 39:  //right
      case 40:  //down
        if (this.state.exercises && this.state.exercises.length > 0) {
          event.currentTarget.parentElement.parentElement.id = this.state.currentExerciseNumber;
          this.onMoveDown(event);
        }
        break;
      default:
    }
  }

  onNext = event => {
    const currentExerciseNumber = this.state.currentExerciseNumber === null ? -1 : this.state.currentExerciseNumber;
    if (currentExerciseNumber < this.state.exercises.length - 1) {
      this.setState({ currentExerciseNumber: currentExerciseNumber + 1});
    }
  }

  onPrevious = event => {
    const currentExerciseNumber = this.state.currentExerciseNumber === null ? this.state.exercises.length : this.state.currentExerciseNumber;
    if (currentExerciseNumber > 0) {
      this.setState({ currentExerciseNumber: currentExerciseNumber - 1});
    }
  }
  
  onChangeSearchText = event => {
    const searchText = event.target.value;
    var searchTerm = new RegExp(searchText, 'gi');
    let instructions = [];
    
    if (searchText.length === 1) {
      instructions = this.state.allInstructions.slice(0).sort((a, b) => Number(a.ordinal) - Number(b.ordinal));
    } else {
      instructions = this.state.allInstructions.filter(instruction => instruction.id.toUpperCase() === searchText.toUpperCase());
    }
    
    if (instructions.length === 0) {
      instructions = this.state.allInstructions.filter(instruction => instruction.name.match(searchTerm));
    }

    if (instructions.length === 0) {
      instructions = this.state.allInstructions.filter(instruction => instruction.id.match(searchTerm));
    }

    this.setState({
      addingExercise: searchText.length > 0,
      searchText,
      instructions
    });

    window.scrollTo(0, 0);
  }

  // onChangeProgramName = event => {
  //   this.setState({programName: event.target.value});    
  // }

  // onChangeMessage = event => {
  //   this.setState({message: event.target.value});    
  // }

  onShowInstruction = event => {
    event.stopPropagation();
    const instructionId = event.target.parentElement.id;
    const showingInstruction = this.state.instructions.find(instruction => instruction.id === instructionId);
    this.setState({
      showingInstructionStep: 0,
      showingInstruction
    });
  }

  onCancelShowInstruction = event => {
    this.setState({
      showingInstruction: null,
    });
    this.onFocusSearchBox();
  }

  onToggleInstructionDetails = event => {
    this.setState({
      showingInstructionDetails: !this.state.showingInstructionDetails
    })
  }

  onPreviousStep = event => {
    const showingInstructionStep = this.state.showingInstructionStep < 1 ? 0 : this.state.showingInstructionStep - 1;
    this.setState({showingInstructionStep});
  }

  onNextStep = event => {
    const showingInstructionStep = this.state.showingInstructionStep >= this.state.showingInstruction.steps.length - 1 ? this.state.showingInstruction.steps.length - 1 : this.state.showingInstructionStep + 1;
    this.setState({showingInstructionStep});
  }

  onAddExercise = event => {
    this.setState({addingExercise: true});
  }

  onCancelAddExercise = event => {
    this.setState({
      addingExercise: false,
      searchText: ''
    });
    this.onFocusSearchBox();
  }

  addExercise = event => {
    const currentExerciseNumber = this.state.currentExerciseNumber === null ? this.state.exercises.length : this.state.currentExerciseNumber + 1;
    this.state.instructions.filter(instruction => instruction.id === event.currentTarget.id).forEach(instruction => {
      const newExercises = this.state.exercises.slice(0);
      newExercises.splice(currentExerciseNumber, 0, {
        id: this.state.nextExerciseId,
        instructionId: instruction.id,
        name: instruction.name,
        shortText: instruction.shortText,
        text: instruction.text || '',
        videoId: instruction.videoId,
        repeat: instruction.repeat || 4,
        repeatUnit: instruction.repeatUnit,
        sets: instruction.sets || 2,
        grouped: false,
        groupedFirst: false,
        shortTrack: false,
        multiTrack: false,
        steps: instruction.steps,
        about: instruction.about,
        affects: instruction.affects
      });
      this.markFirstInGroup(newExercises);
      this.setState({
        exercises: newExercises,
        currentExerciseNumber: currentExerciseNumber,
        nextExerciseId: this.state.nextExerciseId + 1,
        addingExercise: false,
        showingInstruction: false,
        searchText: ''
      }, () => {
        db.addExercise(this.state.userId, this.state.currentProgram, newExercises[currentExerciseNumber], currentExerciseNumber);

        for (let i = currentExerciseNumber + 1; i < this.state.exercises.length; i++) {
          db.moveExercise(this.state.userId, this.state.currentProgram, newExercises[i].id, i);
        }
      });
      this.onFocusSearchBox();
    })
  }

  onChangeSets = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.parentElement.id);    
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    currentExercise.sets = event.target.value;
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.state.userId, this.state.currentProgram, currentExercise);
    });
  }

  onChangeRepeat = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.parentElement.id);
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    currentExercise.repeat = event.target.value;
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.state.userId, this.state.currentProgram, currentExercise);
    });
  }

  markFirstInGroup = exercises => {
    let previousGrouped = false;
    let previousGroupedFirst = false;
    const newExercises = exercises.forEach(exercise => {
      const groupedFirstBefore = exercise.groupedFirst;
      exercise.groupedFirst = !previousGroupedFirst && ((exercise.grouped && exercise.groupedFirst) || (exercise.grouped && !previousGrouped));
      if (groupedFirstBefore !== exercise.groupedFirst) {
        db.updateExercise(this.state.userId, this.state.currentProgram, exercise);
      }
      previousGrouped = exercise.grouped;
      previousGroupedFirst = exercise.groupedFirst;
    });
    return newExercises;
  }

  onChangeGrouped = event => {
    event.stopPropagation();
    const newExercises = this.state.exercises.slice(0);
    const currentExerciseNumber = Number(event.currentTarget.parentElement.id);
    const currentExercise = newExercises[currentExerciseNumber];
    const previousExercise = newExercises[currentExerciseNumber - 1];
  
    if (currentExercise.groupedFirst) {
      currentExercise.grouped = false;
      currentExercise.groupedFirst = false;
    } else if (currentExercise.grouped) {
      if (previousExercise && previousExercise.groupedFirst) {
        currentExercise.grouped = false;
      } else {
        currentExercise.groupedFirst = true;
      }
    } else {
      currentExercise.grouped = true;
    }

    this.markFirstInGroup(newExercises);
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.state.userId, this.state.currentProgram, currentExercise);
    });
  }

  onChangeShortTrack = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    currentExercise.shortTrack = !currentExercise.shortTrack;
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.state.userId, this.state.currentProgram, currentExercise);
    });
  }

  onChangeMultiTrack = event => {
    event.stopPropagation();
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const newExercises = this.state.exercises.slice(0);
    const currentExercise = newExercises[currentExerciseIndex];
    currentExercise.multiTrack = !currentExercise.multiTrack;
    this.setState({exercises: newExercises}, () => {
      db.updateExercise(this.state.userId, this.state.currentProgram, currentExercise);
    });
  }

  onMoveUp = event => {
    event.stopPropagation();
    const newExercises = this.state.exercises.slice(0);
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const currentExerciseId = newExercises[currentExerciseIndex].id;
    const previousExerciseIndex = currentExerciseIndex < 1 ? 0 : currentExerciseIndex - 1;
    const previousExerciseId = newExercises[previousExerciseIndex].id;
    const previousExercise = newExercises[previousExerciseIndex];
    newExercises[previousExerciseIndex] = newExercises[currentExerciseIndex];
    newExercises[currentExerciseIndex] = previousExercise;
    this.markFirstInGroup(newExercises);
    this.setState({
      exercises: newExercises,
      currentExerciseNumber: previousExerciseIndex
    }, () => {
      db.moveExercise(this.state.userId, this.state.currentProgram, previousExerciseId, currentExerciseIndex);
      db.moveExercise(this.state.userId, this.state.currentProgram, currentExerciseId, previousExerciseIndex);
    });
  }

  onMoveDown = event => {
    event.stopPropagation();
    const newExercises = this.state.exercises.slice(0);
    const currentExerciseIndex = Number(event.currentTarget.parentElement.parentElement.id);
    const currentExerciseId = newExercises[currentExerciseIndex].id;
    const nextExerciseIndex = currentExerciseIndex === newExercises.length - 1 ? currentExerciseIndex : currentExerciseIndex + 1;
    const nextExerciseId = newExercises[nextExerciseIndex].id;
    const nextExercise = newExercises[nextExerciseIndex];
    newExercises[nextExerciseIndex] = newExercises[currentExerciseIndex];
    newExercises[currentExerciseIndex] = nextExercise;
    this.markFirstInGroup(newExercises);
    this.setState({
      exercises: newExercises,
      currentExerciseNumber: nextExerciseIndex
    }, () => {
      db.moveExercise(this.state.userId, this.state.currentProgram, nextExerciseId, currentExerciseIndex);
      db.moveExercise(this.state.userId, this.state.currentProgram, currentExerciseId, nextExerciseIndex);
    });
  }

  onRemoveExercise = event => {
    const addingExercise = this.state.exercises.length === 1 ? true : false;    
    const currentExerciseNumber = addingExercise ? 0 : this.state.currentExerciseNumber > 1 ? this.state.currentExerciseNumber - 1 : 1;
    const exercise = this.state.exercises[this.state.currentExerciseNumber];
    const exercises = this.state.exercises.filter((_, index) => index !== Number(event.currentTarget.parentElement.parentElement.id));
    this.markFirstInGroup(exercises);
    this.setState({
      exercises,
      currentExerciseNumber,
      addingExercise
    }, () => {
      db.removeExercise(this.state.userId, this.state.currentProgram, exercise);

      for (let i = currentExerciseNumber + 1; i < this.state.exercises.length; i++) {
        db.moveExercise(this.state.userId, this.state.currentProgram, exercises[i].id, i);
      }
    });
    this.onFocusSearchBox();
  }

  componentDidMount() {
    const self = this;
    const queryString = this.getQueryStringParameters(this.props.location.search);
    const userId = queryString.user;
    const programId = Number(queryString.program || 0);
    const templateId = Number(queryString.template || 0);

    self.setState({
      authenticating: true
    });

    firebase.auth.onAuthStateChanged(user => {
      if (!user) {
        return this.redirectToRoot("Not authenticated!");
      }

      self.setState({
        authUser: user,
        authenticating: false
      });

      db.getUser(userId || user.email)
        .then(user => {
          self.setState({
            user,
            userId: user.id
          });
          
          // navigate(`?user=${encodeURIComponent(user.id)}&program=${programId}`);
          return user;
        })
        .then(user => {
          if (templateId) {
            return db.createProgram(user.id, user.name, user.currentProgram).then(program => {
              return db.getProgram(this.state.authUser.email, templateId).then(template => {
                program.name = template.name;
                program.message = template.message;
                return program;
              });
            });
          } else if (programId) {
            return db.getProgram(user.id, programId);
          } else {
            return db.createProgram(user.id, user.name, user.currentProgram);
          }
        })
        .then(program => {
          self.setState({
            // programName: program.name,
            // message: program.message || this.state.message,
            currentProgram: program.id
          });
          console.log("Current program: ", program.id);

          if (templateId) {
            return db.getExercises(this.state.authUser.email, templateId).then(exercises => {
              exercises.forEach((exercise, index) => { db.addExercise(this.state.user.id, program.id, exercise, index + 1)});
              return exercises;
            });
          } else {
            return db.getExercises(this.state.user.id, program.id);
          }
        })
        .then(exercises => {
          self.setState({
            exercises,
            nextExerciseId: Math.max(...exercises.map(exercise => exercise.id), 0) + 1
          }, () => {
            console.log("Exercises loaded: ", exercises.length);
          });
        })
        .catch(errorMessage => { this.redirectToRoot(errorMessage); });

      db.getPractitioner()
        .then(practitioner => {
          self.setState({ practitioner });
          return practitioner;
        })
        .then((practitioner) => { return db.getInstructions(practitioner.level); })
        .then((allInstructions) => {
          self.setState({ allInstructions }, () => {
          if (self.state.searchText.length > 0) {
            self.onChangeSearchText({target: { value: self.state.searchText } });
          }
          console.log("Instructions loaded: ", allInstructions.length); })
        })
        .catch(errorMessage => { this.redirectToRoot(errorMessage); });
    });
  }
  
  redirectToRoot(errorMessage) {
    console.log(errorMessage);
    //window.location.href = '/';
  }

  getQueryStringParameters = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
  };

  // renderAddMessage() {
  //   return (
  //     <Layout>
  //       <Header>
  //         <Heading backClick={this.onCancelAddMessage}>{ this.state.user ? this.state.user.name : null }</Heading>
  //         <InputContainer>
  //           <InputText value={this.state.programName} onChange={this.onChangeProgramName} placeholder={"Namn på programmet"} autoFocus />
  //         </InputContainer>
  //       </Header>

  //       <Content>
  //         <Info>
  //           <InputTextArea value={this.state.message} onChange={this.onChangeMessage} placeholder={"Skriv ett meddelande till kunden."} autoFocus />
  //         </Info>
  //       </Content>

  //       { this.state.user.id === this.state.practitioner.id ?
  //         <MainAction>
  //           <GreenLargeButton onClick={this.onPublishProgramPractitioner}>Spara som mall</GreenLargeButton>
  //         </MainAction>
  //       :
  //         <MainAction spaceBetween={true}>
  //           <GreenLargeButton onClick={this.onPublishProgramPractitioner}>Till mig</GreenLargeButton>
  //           <GreenLargeButton onClick={this.onPublishProgram}>Till kund</GreenLargeButton>
  //         </MainAction>
        
  //       }
  //     </Layout>
  //   );
  // }

  renderAddExercise() {
    return (
      <Layout>
        <Header>
          <Heading backClick={this.onCancelAddExercise}>
            { this.state.user ? this.state.user.name : '...' }
          </Heading>
          <SearchBox setSearchBoxRef={this.setSearchBoxRef} value={this.state.searchText} onChange={this.onChangeSearchText} onArrowKeys={this.onKeypress} placeholder={"RS, Quad, Hooklying..."} />
        </Header>
        
        <Content>
          <SearchHits hits={this.state.instructions}>
            <SearchHit onClick={this.addExercise} onDetailsClick={this.onShowInstruction} />
          </SearchHits>
        </Content>
      </Layout>
    );
  }

  renderShowInstruction() {
    const instruction = this.state.showingInstruction;
    const name = instruction.name;
    const displayName = name[0].toUpperCase() + name.substring(1);
    const step = this.state.showingInstructionStep;
    const images = this.props.data.allFile.edges;
    const imageFilename = this.getImageFilename(instruction, step);
    const image = images.find(image => image.node.base === imageFilename);
    const title = instruction.steps[step].title ? instruction.steps[step].title.replace('{repeat}', instruction.repeat).replace('{sets}', instruction.sets): null;
    const revealText = this.state.showingInstructionDetails ? "mindre" : "mer";
    //const text = instruction.steps.length > 2 ? '• ' + step.text.replace(/\. /g, '\n• ') : step.text;
    //{ String.fromCharCode(65 + step) }.&nbsp;
    return (
      <Layout>
        <Header>
          <Heading backClick={this.onCancelShowInstruction} multiLine={true}>{displayName}</Heading>
        </Header>
        
        <Content>
          <ImageSpacer>
          { image ? <Image fluid={image.node.childImageSharp.fluid} /> : null }
          </ImageSpacer>

          <InfoBottom onClick={this.onToggleInstructionDetails}>
            { title ?
                <Title>{title} <TextAction>{revealText}</TextAction></Title>
              :
                instruction.steps[step].text
            }

            { 
              <List>
              {
                (this.state.showingInstructionDetails && instruction.steps[step].directives) ?
                  instruction.steps[step].directives.map((directive, index) => <li key={index}>{directive}</li>)
                :
                  null
              }
              </List>
            }
          </InfoBottom>
        </Content>

        <MainAction spaceBetween={true}>
          <SecondaryButton hidden={step === 0} onClick={this.onPreviousStep}>&lt;</SecondaryButton>
          <SecondaryButton id={instruction.id} onClick={this.addExercise}>Lägg till</SecondaryButton>
          <SecondaryButton hidden={step >= instruction.steps.length - 1} onClick={this.onNextStep}>&gt;</SecondaryButton>
        </MainAction>
      </Layout>
    );
  }

  getImageFilename(instruction, currentStep) {
    let previousStep = currentStep;
    while (previousStep > 0 && !instruction.steps[previousStep].image) {
      previousStep--;
    }
    return instruction.steps[previousStep].image
  }

  renderShowList() {
    return (
      <Layout>
        <Header>
          <Heading backLink={`/show?user=${encodeURIComponent(this.state.userId)}`}>{ this.state.user ? this.state.user.name : null }</Heading>
          <SearchBox setSearchBoxRef={this.setSearchBoxRef} value={this.state.searchText} onChange={this.onChangeSearchText} onArrowKeys={this.onKeypress} placeholder={"RS, Quad, Hooklying..."} />
        </Header>
        
        <Content>
          <SortableList
            items={this.state.exercises}
            currentExerciseNumber={this.state.currentExerciseNumber}
            onSelect={this.onSelect}
            onArrowKeys={this.onKeypress}
            onChangeShortTrack={this.onChangeShortTrack}
            onChangeMultiTrack={this.onChangeMultiTrack}
            onChangeSets={this.onChangeSets}
            onChangeRepeat={this.onChangeRepeat}
            onChangeGrouped={this.onChangeGrouped}
            onMoveUp={this.onMoveUp}
            onMoveDown={this.onMoveDown}
            onRemoveExercise={this.onRemoveExercise}
          />
        </Content>

        <MainAction>
          <Link to={`/show?user=${encodeURIComponent(this.state.userId)}&program=${this.state.currentProgram}`}><SecondaryButton>Granska</SecondaryButton></Link>
        </MainAction>
      </Layout>
    );
  }

  render() {
    // if (this.state.addingMessage) {
    //   return this.renderAddMessage();
    // }

    if (this.state.showingInstruction) {
      return this.renderShowInstruction();
    }

    if (this.state.addingExercise) {
      return this.renderAddExercise();
    }

    return this.renderShowList();
  }
}

export default NewPage;

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          base,
          childImageSharp {
            fluid(maxWidth: 834) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
